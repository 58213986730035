
.article-body {
    margin: auto;
    padding: 20px;
    max-width: 800px;
    text-align: left;
}

.article-body>* {
    width: 100% !important;
    height: 100% !important;
}

.article-body img {
    width: 100%;
}

.acupoint-title {
    margin: 0 0 10pt 0;
}

.article-content h1 {
    font-size: 20px;
}

.article-content>.outline {
    background-color: #f4f4f4;
    color: #693A10;
    padding: 5px;
    border-bottom: 1px dashed #CCC;
}

.article-body a {
    pointer-events: none;
    text-decoration-line: none;
    color: #333;
}

.article-body dd, .article-body dt {
    margin: 0 !important;
    padding: 0 !important;
}

.author, .view-sum {
    font-size: 18px;
    color: gray;
    margin: 6px 0;
}

.source {
    font-size: 10px;
    color: #bdbdbd;
    margin-top: 150px;
}

.nmtcm-sub>.li {
    color: #27677e;
    font-weight: bold;
}

.nmtcm-content {
    color: #27677e;
}